import {
  Description,
  Field,
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/16/solid";
import {
  useThemeStore,
  useToastStore,
} from "../../../../../context/ThemeState";
import { Country } from "../../../../../types/types";
import useFetch from "../../../../../Helpers/CustomFetcher";
import { SERVER_URL } from "../../../../../Config/Constants";

export default function CountryList({
  formState,
  setFormState,
}: {
  formState: any;
  setFormState: (value: any) => void;
}) {
  const { theme } = useThemeStore();
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const [data, loading]: any = useFetch(
    `${SERVER_URL}/api/countries`,
    headers
  );
  if (data?.content?.length > 0) {
    return (
      <Field>
        <Label
          className={`text-sm/6 font-medium ${
            theme === "light" ? "text-black" : "text-white"
          }`}
        >
          Country
        </Label>
        <Description className="text-sm/6 text-gray-400">
          Select a country name you belong to
        </Description>
        <div className="relative w-full">
          <Listbox
            value={formState.country}
            onChange={(value) =>
              setFormState((prev: any) => ({ ...prev, country: value }))
            }
          >
            <ListboxButton
              className={`border
                    block w-full rounded-lg py-2 pr-8 pl-3 text-left text-sm
                    focus:outline-none focus:outline-2 focus:-outline-offset-2
                    ${
                      theme === "light"
                        ? "bg-white text-black focus:outline-blue-500 border-gray-300"
                        : "bg-white/5 text-white focus:outline-blue-500 border-stone-700"
                    }
                  `}
            >
              {formState.country?.name !== ""
                ? formState?.country?.name
                : "Select"}
              <ChevronDownIcon
                className={`group pointer-events-none absolute top-2.5 right-2.5 size-4 ${
                  theme === "light" ? "fill-black/60" : "fill-white/60"
                }`}
                aria-hidden="true"
              />
            </ListboxButton>
            <ListboxOptions
              anchor="bottom"
              transition
              className={`md:w-[350px]  w-[280px]  absolute rounded-xl border p-1 focus:outline-none transition duration-100 ease-in z-40 mt-2 
            ${
              theme === "light"
                ? "border-gray-200 bg-white"
                : "border-black/5 bg-stone-800 text-white"
            }
            
            opacity-100 transition-opacity
          `}
            >
              {data?.content?.map((country: Country, index: number) => (
                <ListboxOption
                  key={index}
                  value={country}
                  className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-black/10"
                >
                  <CheckIcon
                    className={`invisible size-4 group-data-[selected]:visible ${
                      theme === "light" ? "text-black" : "text-white"
                    }`}
                  />
                  <div className="text-sm/6 ">{country.name}</div>
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Listbox>
        </div>
      </Field>
    );
  } else {
    return <p className="text-center font-semibold">No Countries To Show</p>;
  }
}
