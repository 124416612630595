import {
  Button,
  Description,
  Field,
  Fieldset,
  Input,
  Label,
} from "@headlessui/react";

import { FormEvent, useEffect, useRef, useState } from "react";
import PlusIcon from "../../UI/Icons/Journals/PlusIcon";
import { useThemeStore, useToastStore } from "../../../context/ThemeState";
import SearchSymbols from "./SearchSymbols";
import clsx from "clsx";
import Drawer from "./Drawer";
import ChargesCalculator from "./ChargesCalculator";
import ButtonTrade from "../../UI/buttons/CreateTradesButton";
import cookies from "../../../Helpers/Cookies";
import localStorageHelpers from "../../../Helpers/LocalStorage";
import { TradesCalculator } from "../../../Helpers/TradesCalculator";
import { CallBacks, SERVER_ENDPOINT } from "../../../Config/Callbacks";
import { SERVER_URL } from "../../../Config/Constants";

export default function AddTrades() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLaoding, setIsLoading] = useState(false);
  const [dateShow, setShowDate] = useState("");
  const dateRef = useRef<any>();
  const { theme } = useThemeStore();
  const { showToast } = useToastStore();
  const formRef = useRef<HTMLFormElement>(null);
  const tradescalculator = new TradesCalculator();

  const [formState, setFormState] = useState({
    symbol: {
      id: "",
      symbol: "",
      name: "",
      industry: "",
    },
    date: "",
    quantity: 0,
    type: "",
    price: 0,
    sl: 0,
    slPercent: 0,
    charges: "",
  });
  const user = cookies.get("getuser")();
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const [data, setData] = useState<any>([]);
  const journal_id = localStorageHelpers.get("getdata")("journal_id");

  useEffect(() => {
    (async () => {
      const Callbacks = new CallBacks();
      try {
        const response = await Callbacks.GET(
          `${SERVER_URL}/api/journals/id/${journal_id}`,
          { headers }
        );

        setData(response);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  async function handleCreateTrade(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const formData = {
      date: formState.date,
      lastOrderDate: formState.date,
      price: formState.price,
      stoploss: formState.sl,
      quantity: formState.quantity,
      type: "BUY",
      charges: Number(formState.charges),
      capital: data.accountValue,
      journal: journal_id,
      symbol: formState.symbol.id,
      user: user.id,
      rpt: tradescalculator.RiskPerTrade(
        formState.quantity,
        formState.price,
        formState.sl
      ),
      rptPercent: tradescalculator.RiskPerTradePercent(
        data.accountValue,
        tradescalculator.RiskPerTrade(
          formState.quantity,
          formState.price,
          formState.sl
        )
      ),
      positionSize: formState.price * formState.quantity,
      slPercent: formState.slPercent,
      deployedValue: data.accountValue,
      capitalDeployed: data.accountValue,
      remainingQuantity: formState.quantity,
      averageExitPrice: formState.price,
      openRisk: tradescalculator.OpenRisk(
        formState.quantity,
        formState.price,
        formState.sl
      ),
      exposure: tradescalculator.exposure(formState.quantity, formState.price),
      positionSizePercent: tradescalculator.positionSizePercent(
        formState.price * formState.quantity,
        data.accountValue
      ),
      profit: 0,
      netProfit: -Number(formState?.charges),
    };
    console.log(formData);
    const callback = new CallBacks();
    setIsLoading(true);
    try {
      const response = await callback.POST(`${SERVER_URL}/api/trades/create`, {
        headers,
        body: JSON.stringify(formData),
      });
      showToast(response.message, "success");
    } catch (error: any) {
      showToast(error.message, "error");
    } finally {
      setIsLoading(false);
      formRef?.current?.reset();
    }
  }
  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        className={`inline-flex items-center gap-2 rounded-lg  md:py-1 py-1.5 px-3 text-sm/6 font-semibold shadow-inner focus:outline-none ${theme === "light"
          ? "data-[hover]:bg-stone-50 data-[focus]:outline-1 data-[focus]:outline-white border bg-stone-100"
          : "data-[hover]:bg-stone-600 data-[focus]:outline-1 data-[focus]:outline-white border border-stone-800 bg-stone-900 text-white"
          }`}
      >
        <PlusIcon styleString="h-5 w-5 " />
        <p className="md:block hidden">Trade</p>
      </Button>
      <Drawer
        isOpen={isOpen}
        close={() => setIsOpen(false)}
        title="Create trades"
      >

        <div
          className={`h-full ${theme === "light" ? "text-stone-800 h-full" : "text-white"
            }`}
        >
          <button
            type="button"
            aria-controls="drawer-navigation"
            onClick={() => setIsOpen(false)}
            className={`rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center ${theme === "light" ? "text-gray-800" : "text-gray-200"}`}
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close menu</span>
          </button>
          <form onSubmit={handleCreateTrade} ref={formRef}>
            <Fieldset>
              <Field>
                <Label className={"font-semibold text-sm"}>Symbol</Label>
                <SearchSymbols
                  setFormState={setFormState}
                  formStatevalue={formState.symbol}
                />
              </Field>
              <Field>
                <Label className={"font-semibold text-sm"}>Date</Label>
                <Input
                  type="date"
                  ref={dateRef}
                  onClick={() => dateRef?.current?.showPicker()}
                  onChange={(e) => {
                    setShowDate(e.target.value);
                    const date = new Date(e.target.value);
                    setFormState((prev) => ({
                      ...prev,
                      date: date.toISOString(),
                    }));
                  }}
                  value={dateShow}
                  className={clsx(
                    `${theme === "light"
                      ? "border border-stone-500/30"
                      : "border border-stone-800/25"
                    }`,
                    "mt-1 block w-full rounded-lg bg-white/5 py-1.5 px-3 text-sm/6 ",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-blue-500"
                  )}
                />
              </Field>
              <Field>
                <Label className={"font-semibold text-sm"}>Quantity</Label>

                <Input
                  type="number"
                  onChange={(e) =>
                    setFormState((prev) => ({
                      ...prev,
                      quantity: Number(e.target.value),
                    }))
                  }
                  value={formState.quantity}
                  className={clsx(
                    `${theme === "light"
                      ? "border border-stone-500/30"
                      : "border border-stone-800/25"
                    }`,
                    "mt-1 block w-full rounded-lg bg-white/5 py-1.5 px-3 text-sm/6 ",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-blue-500"
                  )}
                />
              </Field>
              <Field>
                <Label className={"font-semibold text-sm"}>Type</Label>

                <Input
                  type="text"
                  onChange={(e) =>
                    setFormState((prev) => ({
                      ...prev,
                      type: e.target.value,
                    }))
                  }
                  value={formState.type}
                  className={clsx(
                    `${theme === "light"
                      ? "border border-stone-500/30"
                      : "border border-stone-800/25"
                    }`,
                    "mt-1 block w-full rounded-lg bg-white/5 py-1.5 px-3 text-sm/6 ",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-blue-500"
                  )}
                />
              </Field>
              <Field>
                <Label className={"font-semibold text-sm"}>Price</Label>

                <Input
                  type="number"
                  onChange={(e) => {
                    if (Number(e.target.value) <= 0) {
                      return;
                    }
                    setFormState((prev) => ({
                      ...prev,
                      price: Number(e.target.value),
                    }));
                  }}
                  value={formState.price}
                  className={clsx(
                    `${theme === "light"
                      ? "border border-stone-500/30"
                      : "border border-stone-800/25"
                    }`,
                    "mt-1 block w-full rounded-lg bg-white/5 py-1.5 px-3 text-sm/6 ",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-blue-500"
                  )}
                />
              </Field>
              <div className="flex items-center gap-5">
                <Field>
                  <Label className={"font-semibold text-sm"}>Stoploss</Label>
                  <Input
                    type="number"
                    onChange={(e) => {
                      const slPercent = tradescalculator.stopLossPercent(
                        formState.price,
                        Number(e.target.value)
                      );
                      setFormState((prev) => ({
                        ...prev,
                        sl: Number(e.target.value),
                        slPercent: Number((slPercent * 100).toFixed(5)),
                      }));
                    }}
                    value={formState.sl}
                    className={clsx(
                      `${theme === "light"
                        ? "border border-stone-500/30"
                        : "border border-stone-800/25"
                      }`,
                      "mt-1 block w-full rounded-lg bg-white/5 py-1.5 px-3 text-sm/6 ",
                      "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-blue-500"
                    )}
                  />
                </Field>
                <Field>
                  <Label className={"font-semibold text-sm"}>Stoploss %</Label>
                  <Input
                    type="number"
                    value={formState.slPercent}
                    readOnly
                    className={clsx(
                      `${theme === "light"
                        ? "border border-stone-500/30"
                        : "border border-stone-800/25"
                      }`,
                      "mt-1 block w-full rounded-lg bg-white/5 py-1.5 px-3 text-sm/6 ",
                      "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-blue-500"
                    )}
                  />
                </Field>
              </div>
              <Field>
                <Label className={"font-semibold text-sm"}>Charges</Label>
                <Input
                  value={formState.charges}
                  onChange={(e) =>
                    setFormState((prev) => ({
                      ...prev,
                      charges: e.target.value,
                    }))
                  }
                  className={clsx(
                    `${theme === "light"
                      ? "border border-stone-500/30"
                      : "border border-stone-800/25"
                    }`,
                    "mt-1 block w-full rounded-lg bg-white/5 py-1.5 px-3 text-sm/6 ",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-blue-500"
                  )}
                />
              </Field>
              <ChargesCalculator
                setFormState={setFormState}
                transactionPrice={Number(formState.price)}
                type="BUY"
                quantity={formState.quantity}
              />
            </Fieldset>
            <ButtonTrade isLoading={isLaoding} />
          </form>
        </div>
      </Drawer>
    </>
  );
}
