import { Link, useLocation, useParams } from "react-router-dom";
import { useThemeStore } from "../../../context/ThemeState";
import DrawerNavigation from "../MenuOverlays/DrawerNavigation";
import { useState } from "react";
import MenuIcon from "../Icons/Journals/MenuIcon";
import AddTrades from "../../user/trades/AddTrades";
import JournalList from "./JournalList";
import localStorageHelpers from "../../../Helpers/LocalStorage";
import { UserIcon } from "@heroicons/react/24/outline";
import { Button } from "@headlessui/react";
import { MoonIcon, SunIcon } from "@heroicons/react/16/solid";

export default function Header() {
  const { pathname } = useLocation();
  const { theme, setTheme } = useThemeStore();
  const [isOpen, setIsOpen] = useState(false);
  const journalName = localStorageHelpers.get("getdata")("journal_name");
  console.log("this is running");
  return (
    <header
      className={`w-full fixed top-0 z-[100] shadow ${pathname.startsWith("/journal/") ? "flex" : "hidden"
        }  ${theme === "light" ? "bg-white" : "bg-stone-950"}`}
    >
      <div className="md:block hidden w-full">
        <nav className="px-5 py-2 w-full">
          <ul className="flex justify-between font-semibold items-center w-full ">
            <div className="flex items-center font-semibold gap-1">
              <NavigationComponents url={`/journal/dashboard`}>
                Dashboard
              </NavigationComponents>
              <NavigationComponents url={`/journal/trades`}>
                Trades
              </NavigationComponents>
              <NavigationComponents url={`/journal/positions`}>
                Positions
              </NavigationComponents>
              <NavigationComponents url={`/journal/summary`}>
                Summary
              </NavigationComponents>
              <NavigationComponents url={`/journal/funds`}>
                Funds
              </NavigationComponents>
              <NavigationComponents url={`/journal/symbols`}>
                Symbols
              </NavigationComponents>
              <NavigationComponents
                url={`/journal/feed?eventType=ORDER%2CCLOSE_ORDERS%2CNOTE%2CNOTE_JOURNAL%2CCHART%2CCHART_JOURNAL`}
              >
                Feed
              </NavigationComponents>
            </div>
            <div className="flex items-center gap-1.5">
              <JournalList journalName={journalName} />
              <AddTrades />
              <Button
                onClick={() => setTheme(theme === "light" ? "dark" : "light")}
                className={`p-1.5 rounded-full ${theme === "light"
                  ? "text-gray-700 hover:bg-gray-200"
                  : "text-white hover:bg-stone-800"
                  }`}
              >
                {theme === "light" ? (
                  <MoonIcon className="h-5 w-5" />
                ) : (
                  <SunIcon className="h-5 w-5 text-yellow-500" />
                )}
              </Button>
              <Link to={"/journal/profile"}>
                <p
                  className={`border p-1 rounded-full ${theme === "light"
                    ? "text-gray-800 border-stone-300 hover:bg-gray-100"
                    : "text-white border-stone-700 bg-stone-800 hover:bg-stone-700"
                    }`}
                >
                  <UserIcon className="h-5 w-5" />
                </p>
              </Link>
            </div>
          </ul>
        </nav>
      </div>
      <div className="flex items-start justify-center md:hidden md:px-5 px-3 py-2">
        <button
          onClick={() => setIsOpen(true)}
          className={
            theme === "light"
              ? "bg-gray-100 p-2 rounded-full"
              : "bg-stone-800 p-2 rounded-full text-white"
          }
        >
          <MenuIcon stylesString="h-6 w-6" />
        </button>
        <div className="flex items-center gap-3 w-full">
          <DrawerNavigation isOpen={isOpen} onClose={() => setIsOpen(false)} />
          <div className="flex items-center gap-3">
            <JournalList journalName={journalName} />
            <AddTrades />
            <Button
              onClick={() => setTheme(theme === "light" ? "dark" : "light")}
              className={`p-1.5 rounded-full ${theme === "light"
                ? "text-gray-700 hover:bg-gray-200"
                : "text-white hover:bg-stone-800"
                }`}
            >
              {theme === "light" ? (
                <MoonIcon className="h-5 w-5" />
              ) : (
                <SunIcon className="h-5 w-5 text-yellow-500" />
              )}
            </Button>
            <Link to={"/journal/profile"}>
              <p
                className={`border p-1 rounded-full ${theme === "light"
                  ? "text-gray-800 border-stone-300 hover:bg-gray-100"
                  : "text-white border-stone-700 bg-stone-800 hover:bg-stone-700"
                  }`}
              >
                <UserIcon className="h-5 w-5" />
              </p>
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
}
// /journal/feed?eventType=ORDER%2CCLOSE_ORDERS%2CNOTE%2CNOTE_JOURNAL%2CCHART%2CCHART_JOURNAL
function NavigationComponents({
  children,
  url,
}: {
  children: React.ReactNode;
  url: string;
}) {
  const { theme } = useThemeStore();
  const { pathname } = useLocation();
  return (
    <Link to={url}>
      <li
        className={` list-none px-4 py-1.5 rounded-lg  ${pathname === `/journal/${children?.toString().toLocaleLowerCase()}` ||
          pathname.startsWith(`/journal/${children?.toString()?.toLowerCase()}`)
          ? `${theme === "light"
            ? "text-black bg-gray-100"
            : "text-white bg-stone-900"
          }`
          : `${theme === "light"
            ? "hover:bg-gray-100 active:ring-2 ring-blue-700 hover:text-black text-gray-400"
            : "hover:bg-stone-900 active:ring-2 ring-blue-700 hover:text-white text-gray-600"
          }`
          } `}
      >
        {children}
      </li>
    </Link>
  );
}
