import {
  Button,
  Tab,
  TabGroup,
  TabList,
} from "@headlessui/react";
import { useThemeStore } from "../../../context/ThemeState";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import CreateFunds from "./AddFunds2";

const categories = [
  {
    name: "Funds",
  },
  {
    name: "Adjustments",
  },
];

export default function Tabs({
  currentTab,
  setCurrentTab,
  setClose,
  setIsOpenHelp,
  isOpen,
}: {
  currentTab: "Funds" | "Adjustments";
  setCurrentTab: (value: "Funds" | "Adjustments") => void;
  setClose: () => void;
  setIsOpenHelp: (value: boolean) => void;
  isOpen: boolean;
}) {
  const { theme } = useThemeStore();

  return (
    <div
      className={`flex w-full justify-between  py-3  ${
        theme === "dark" ? "bg-stone-950" : "bg-white"
      }`}
    >
      <div className="max-w-md">
        <TabGroup
          className={`py-1 px-1 rounded-lg ${
            theme === "light" ? "bg-gray-100" : "bg-stone-800"
          }`}
        >
          <TabList className="flex md:gap-3">
            {categories.map(({ name }) => (
              <Tab
                key={name}
                className={({ selected }) =>
                  `rounded-lg py-1 font-semibold focus:outline-none px-2
                  ${
                    selected
                      ? theme === "dark"
                        ? "bg-stone-800 shadow"
                        : "bg-white shadow"
                      : ""
                  }
                  ${theme === "dark" ? "text-white" : "text-gray-800"} 
                  hover:${theme === "dark" ? "bg-stone-700" : "bg-gray-100"}`
                }
              >
                {name}
              </Tab>
            ))}
          </TabList>
        </TabGroup>
      </div>
      <div className="flex items-center gap-5">
        <Button
          onClick={() => setIsOpenHelp(!isOpen)}
          className={`inline-flex items-center gap-2 rounded-md  py-1.5 px-3 font-semibold  data-[focus]:outline-1  focus:outline-none shadow-inner ${
            theme === "light"
              ? "data-[hover]:bg-gray-100 data-[open]:bg-gray-100 bg-gray-200  text-black shadow-white/70 shadow-inner"
              : "data-[hover]:bg-gray-600 data-[open]:bg-gray-700 bg-gray-700 data-[focus]:outline-white text-white"
          }`}
        >
          Help <ChevronDownIcon className="h-5 w-5" />
        </Button>
        <CreateFunds onClose={setClose} />
      </div>
    </div>
  );
}
/***/
