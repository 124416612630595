import {
  Button,
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { useState } from "react";
import { Fragment } from "react/jsx-runtime";
import QuestionMark from "../../UI/Icons/Journals/QuestionMark";
import { useThemeStore } from "../../../context/ThemeState";

const DocumentationTrades = () => {
  const [isOpen, closeModal] = useState(false);
  const { theme } = useThemeStore();
  return (
    <>
      <Button
        className={`text-semibold px-3 py-1.5 flex items-center border shadow-inner rounded-lg gap-2 ${
          theme === "light"
            ? "text-black border-gray-200"
            : "text-white border-stone-700"
        }`}
        onClick={() => closeModal(true)}
      >
        <QuestionMark styleString="h-5 w-5" />
        <p className="md:block hidden">Help</p>
      </Button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[400] " onClose={closeModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="fixed inset-0 bg-black bg-opacity-30" />
          </TransitionChild>

          <div className="fixed inset-0 flex items-center justify-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative w-screen bg-white shadow-xl overflow-y-auto h-screen">
                <div className="flex flex-col px-5 gap-5 py-5">
                  <DialogTitle className="text-xl font-semibold ">
                    Trade Metrics Explained
                  </DialogTitle>
                  <div className="absolute right-0 top-0 p-5">
                    <button
                      onClick={() => closeModal(false)}
                      className="text-gray-500 hover:text-gray-800"
                    >
                      <span className="sr-only">Close</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="h-6 w-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  <div>
                    <h1 className="border-b pb-2 text-lg font-semibold">SL%</h1>
                    <p className="flex flex-col items-start">
                      It calculates the percentage distance between the entry
                      price and the stop loss.
                      <code className="relative rounded bg-gray-a4 px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                        = (Entry Price - SL) ÷ Entry Price.
                      </code>
                    </p>

                    <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                      Position Size
                    </h1>
                    <code className="relative rounded bg-gray-a4 px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                      = Quantity Traded x Entry Price
                    </code>

                    <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                      Position Size %
                    </h1>
                    <code className="relative rounded bg-gray-a4 px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                      = Position Size ÷ Account Value as on the trade open date.
                    </code>

                    <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                      RPT
                    </h1>
                    <p className="flex flex-col items-start">
                      It stands for Risk Per Trade. It is the loss that would be
                      incurred if the trade is exited at stop loss. It is
                      calculated as
                      <code className="relative rounded bg-gray-a4 px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                        = Quantity x (Entry Price - Stop Loss).
                      </code>
                    </p>

                    <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                      RPT %
                    </h1>
                    <p className="flex flex-col items-start">
                      RPT % calculates the percentage of account value at risk
                      in case the trade fails.
                      <code className="relative rounded bg-gray-a4 px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                        = RPT ÷ Account Value as on the trade open date.
                      </code>
                    </p>

                    <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                      Exit %
                    </h1>
                    <code className="relative rounded bg-gray-a4 px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                      = Quantity Exited ÷ Total Quantity Traded
                    </code>

                    <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                      Gain %
                    </h1>
                    <p className="flex flex-col items-start">
                      It calculates the percentage distance between the entry
                      price and the average exit price.
                      <code className="relative rounded bg-gray-a4 px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                        = (Exit Price - Entry Price) ÷ Entry Price.
                      </code>
                    </p>

                    <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                      Account Value
                    </h1>
                    <p className="flex flex-col items-start">
                      Account Value refers to the value of your account as of
                      the trade open date. It takes into account the net funds
                      added up until the trade open date in addition to the
                      realized profits up until the trade open date.
                      <code className="relative rounded bg-gray-a4 px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                        = Capital Deployed + Realised Profits till date.
                      </code>
                    </p>

                    <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                      Account Gain %
                    </h1>
                    <p className="flex flex-col items-start">
                      AG % refers to the percentage increase in your account
                      value resulting from that trade.
                      <code className="relative rounded bg-gray-a4 px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                        = Net Profit ÷ Account Value as on the trade open date.
                      </code>
                    </p>

                    <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                      Capital Deployed
                    </h1>
                    <p className="flex flex-col items-start">
                      Capital Deployed refers to the capital brought in for
                      trading. It is used to calculate Return On Capital
                      Deployed (RoCD) which is the percentage return earned on
                      the funds deployed for trading.
                      <code className="relative rounded bg-gray-a4 px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                        = Total Funds added till trade open date.
                      </code>
                    </p>

                    <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                      RoCD
                    </h1>
                    <p className="flex flex-col items-start">
                      It refers to the percentage gained from a trade over the
                      capital deployed prevailing at the trade open date. It is
                      a simple metric that shows the return on initial
                      investment.
                      <code className="relative rounded bg-gray-a4 px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                        = Net Profit ÷ Capital Deployed as on the trade open
                        date.
                      </code>
                    </p>

                    <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                      Starting Account Value
                    </h1>
                    <p className="flex flex-col items-start">
                      Starting Account Value refers to the account value at the
                      start of the calendar year (1st Jan). The role of this
                      metric is to calculate yearly account growth i.e. RoSV
                      (Return on Starting Value).
                      <code className="relative rounded bg-gray-a4 px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                        = Net funds added till 31 Dec + Realised profits till 31
                        Dec.
                      </code>
                    </p>

                    <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                      Starting Account Value (Adj.)
                    </h1>
                    <p className="flex flex-col items-start">
                      SAV does not consider deposits/withdrawals made in the
                      current year. Hence, instead of SAV, we use the Starting
                      Account Value (Adj.) to calculate RoSV.
                      <code className="relative rounded bg-gray-a4 px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                        = Starting Account Value (1st Jan) + Net funds added in
                        the current year till trade open date.
                      </code>
                    </p>

                    <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                      RoSV
                    </h1>
                    <p className="flex flex-col items-start">
                      RoSV, which stands for Return on Starting Value,
                      represents the percentage gain on your Starting Account
                      Value (Adj.) for a given calendar year. To calculate the
                      RoSV of each trade, the net profit of the trade is divided
                      by the Starting Account Value (Adjusted) as of the trade's
                      open date. ROCD and ROSV are two different metrics used to
                      calculate returns in trading. ROCD gives the percentage
                      return over the initial capital invested, while ROSV
                      calculates the percentage return over the starting account
                      value for the year, including any deposits or withdrawals
                      made in the current year till the trade was opened. ROSV
                      is useful in measuring the annual return based on the
                      starting account value for that year, while ROCD
                      calculates returns over the net funds added till trade
                      open date.
                      <code className="relative rounded bg-gray-a4 px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                        = Net Profit ÷ Starting Account Value (Adj.) as on the
                        trade open date.
                      </code>
                    </p>

                    <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                      RR
                    </h1>
                    <p className="flex flex-col items-start">
                      R multiple or Risk Reward ratio is used to calculate the
                      profit with respect to risk taken. Can be calculated using
                      either one of below formulas
                      <code className="relative rounded bg-gray-a4 px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                        = Gain % ÷ SL %
                      </code>
                      <code className="relative rounded bg-gray-a4 px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                        = Net Profit ÷ RPT
                      </code>
                    </p>

                    <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                      Charges
                    </h1>
                    <p>
                      It includes all trading charges like brokerage, duties and
                      taxes. It should not include personal income tax.
                    </p>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default DocumentationTrades;
