import { Button } from "@headlessui/react";
import { ArrowLeftIcon } from "@heroicons/react/16/solid";
import { useThemeStore, useToastStore } from "../../../context/ThemeState";
import { useEffect, useState } from "react";
import { CallBacks } from "../../../Config/Callbacks";
import { TradesCalculator } from "../../../Helpers/TradesCalculator";
import { useNavigate, useParams } from "react-router-dom";
import TradeDetailsTable from "./TableTradeDetails";
import ExitTrade from "./ExitTrade";
import CreateNotes from "./CreateNotes";

import TradingNotesContainer from "./TradingNotesContainer";
import { SERVER_URL } from "../../../Config/Constants";

export default function TradesDetails() {
  const { theme } = useThemeStore();
  const { showToast } = useToastStore();
  const [tradesDetails, setTradesDetails] = useState<any>({});
  const calculatorFunctions = new TradesCalculator();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    (async () => {
      try {
        const callbacks = new CallBacks();
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        const response = await callbacks.GET(
          `${SERVER_URL}/api/trades/id/${params.trades_id}`,
          { headers }
        );
        setTradesDetails(response);
      } catch (error: any) {
        showToast(error.message, "error");
      }
    })();
  }, []);

  return (
    <div className="flex flex-col gap-2 min-h-screen  px-3">
      <div className="w-full py-3 px-3 flex items-center justify-between">
        <Button
          className={`flex items-center justify-center gap-2 border px-4 py-1.5 rounded-full  ${theme === "light"
            ? "border-gray-300 bg-gray-100 hover:bg-gray-200"
            : "border-stone-700 bg-stone-800 hover:bg-gray-700"
            }`}
          onClick={() => navigate(-1)}
        >
          <ArrowLeftIcon className="h-5 w-5 text-gray-400" />
          Back
        </Button>
        <div className="flex items-center gap-5">
          <CreateNotes tradeDetails={tradesDetails} />
          {tradesDetails?.remainingQuantity > 0 && (
            <ExitTrade tradeDetails={tradesDetails} />
          )}
        </div>
      </div>
      <div
        className={`p-3 rounded-lg ${theme === "light"
          ? "border border-gray-100"
          : "border border-stone-800"
          }`}
      >
        <div className="flex items-center">
          <p className="font-semibold text-lg">
            {tradesDetails?.symbol?.name} ,{" "}
          </p>
          <p className="text-sm text-gray-500">
            {tradesDetails?.symbol?.sector} ,{" "}
          </p>
          <p className="text-sm text-gray-500">
            {tradesDetails?.symbol?.industry}
          </p>
        </div>
        <div className="flex items-center gap-2 text-sm font-semibold">
          <p>{new Date(tradesDetails?.date).toDateString()} ,</p>
          <p>{tradesDetails.holdingDays} Days ,</p>
          <p>{Math.round(tradesDetails.totalExitPercent)}% closed</p>
        </div>
      </div>
      <div
        className={`flex flex-wrap justify-evenly rounded-lg items-center py-3 ${theme === "light"
          ? "border border-gray-200"
          : "border border-stone-800"
          }`}
      >
        <div className="flex flex-col items-start ">
          <p className="text-sm text-gray-500">Avg. Entry Price</p>
          <p>{Number(tradesDetails.price).toFixed(0)}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500">Avg. Exit Price</p>
          <p>{Number(tradesDetails.totalExitPercent).toFixed(2)}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500">Account Value</p>
          <p>{Number(tradesDetails.deployedValue).toFixed(2)}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500">Position Size</p>
          <div className="flex items-center gap-2">
            <p>
              {Number(tradesDetails.quantity * tradesDetails.price).toFixed(2)}
            </p>
            <p className="text-sm text-gray-500">
              {`(${Number(
                calculatorFunctions.positionSizePercent(
                  tradesDetails.quantity * tradesDetails.price,
                  tradesDetails.capital
                )
              ).toFixed(2)} %)`}
            </p>
          </div>
        </div>
        <div>
          <p className="text-sm text-gray-500">Stoploss</p>
          <div className="flex items-center gap-2">
            <p>{Number(tradesDetails.stoploss).toFixed(2)}</p>
            <p className="text-sm text-gray-500">
              {`(${Number(tradesDetails.slPercent).toFixed(2)} %)`}{" "}
            </p>
          </div>
        </div>
        <div>
          <p className="text-sm text-gray-500">RPT</p>
          <div className="flex items-center gap-2">
            <p>{Number(tradesDetails.rpt).toFixed(2)}</p>
            <p className="text-sm text-gray-500">
              {`(${Number(tradesDetails.rptPercent).toFixed(2)} %)`}{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="w-full">
        <TradeDetailsTable TradeDetails={tradesDetails} />
      </div>
      <TradingNotesContainer tradeDetails={tradesDetails} />
    </div>
  );
}
