import {
  Button,
  Description,
  Field,
  Fieldset,
  Input,
  Label,
} from "@headlessui/react";
import React, { FormEvent, useRef, useState } from "react";
import ModalHeadless from "../../../modals/HeadlessOverlay";
import { useThemeStore } from "../../../../../context/ThemeState";
import ButtonJournal from "../../../buttons/JournalCreateButton";
import CountryList from "./CountryList";
import { CallBacks } from "../../../../../Config/Callbacks";
import cookies from "../../../../../Helpers/Cookies";
import PriceCard from "./PriceCard";
import toastContainer from "../../../../../context/ToastConfiguration";
import { SERVER_URL } from "../../../../../Config/Constants";

export const CreateJournalOverlay = React.memo(
  ({ setIsClosing }: { setIsClosing: () => void }) => {
    const [isLoading, setIsLoading] = useState(false);
    const formRef = useRef<HTMLFormElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [requestStatus, setRequestStatus] = useState({
      type: "",
      message: "",
    });
    const [formState, setFormState] = useState({
      name: "",
      country: {
        id: "",
        name: "",
        code: "",
        currency: "₹",
      },
      plan: { lockInPrice: 0, lockInCurrency: "₹" },
      activated: true,
    });
    const user = cookies.get("getuser")();

    const { theme } = useThemeStore();
    function open() {
      setIsOpen(true);
    }
    function close() {
      setIsOpen(false);
      setIsClosing();
    }

    async function handleCreateJournal(e: FormEvent<HTMLFormElement>) {
      e.preventDefault();
      try {
        const callbacks = new CallBacks();
        setIsLoading(true);
        const formData = {
          name: formState.name,
          countryId: formState?.country?.id,
          userId: user?.id,
          activated: true,
          lockInPrice: formState.plan.lockInPrice,
          lockInCurrency: formState.plan.lockInCurrency,
        };

        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        const response = await callbacks.POST(
          `${SERVER_URL}/api/journals/create`,
          {
            headers,
            body: JSON.stringify(formData),
          }
        );
        setRequestStatus({ message: response.message, type: "success" });
      } catch (error: any) {
        setRequestStatus({
          message: error.message || error.title,
          type: "error",
        });
      } finally {
        setIsLoading(false);
        formRef.current?.reset();
      }
    }

    return (
      <div>
        <Button
          onClick={open}
          className={`rounded-full py-2 px-4 text-sm font-medium focus:outline-none  data-[focus]:outline-1  ${
            theme === "light"
              ? "text-blue-500 x data-[focus]:outline-black border border-blue-500"
              : "text-blue-500 data-[hover]:bg-black/30 border data-[focus]:outline-white border-blue-500"
          }`}
        >
          Create Journal
        </Button>
        <ModalHeadless isOpen={isOpen} close={close} title="Create Journal">
          <div className="md:w-[400px] w-full px-4">
            <form onSubmit={handleCreateJournal} ref={formRef}>
              {requestStatus?.type !== "" && (
                <div className="w-full">
                  {toastContainer.get(requestStatus.type.toUpperCase())({
                    message: requestStatus.message,
                    close: () => setRequestStatus({ message: "", type: "" }),
                  })}
                </div>
              )}
              <Fieldset className={"flex flex-col gap-3"}>
                <Field>
                  <Label
                    className={`text-sm/6 font-medium ${
                      theme === "light" ? "text-black" : "text-white"
                    }`}
                  >
                    Name
                  </Label>
                  <Description className="text-sm/6 text-gray-400">
                    What name you want to give to this journal?
                  </Description>
                  <Input
                    className={`w-full px-4 py-1.5 rounded-lg ${
                      theme === "light"
                        ? "outline-none border focus:border-2 focus:border-blue-500 border-gray-300 "
                        : "outline-none border focus:border-2 focus:border-blue-500 border-stone-700 bg-black/15 text-white"
                    }`}
                    onChange={(e) =>
                      setFormState((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                    value={formState.name}
                  />
                </Field>
                <CountryList
                  formState={formState}
                  setFormState={setFormState}
                />
                <PriceCard
                  plans={[
                    {
                      lockInPrice: 9500,
                      lockInCurrency: formState?.country?.currency,
                    },
                  ]}
                  formState={formState}
                  setFormState={setFormState}
                />
                <ButtonJournal isLoading={isLoading} />
              </Fieldset>
            </form>
          </div>
        </ModalHeadless>
      </div>
    );
  }
);
