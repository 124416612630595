import {
  Button,
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { Fragment } from "react/jsx-runtime";
import QuestionMark from "../../UI/Icons/Journals/QuestionMark";
import { useThemeStore } from "../../../context/ThemeState";
import { useState } from "react";

const SummaryExplanation = () => {
  const { theme } = useThemeStore();
  const [isOpen, closeModal] = useState(false);
  return (
    <>
      <Button
        className={`text-semibold px-3 py-1.5 flex items-center border shadow-inner rounded-lg gap-2 ${
          theme === "light"
            ? "text-black border-gray-200"
            : "text-white border-stone-700"
        }`}
        onClick={() => closeModal(true)}
      >
        <QuestionMark styleString="h-5 w-5" />
        <p className="md:block hidden">Help</p>
      </Button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[400] " onClose={closeModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="fixed inset-0 bg-black bg-opacity-30" />
          </TransitionChild>

          <div className="fixed inset-0 flex items-center justify-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative w-screen bg-white shadow-xl overflow-y-auto h-screen">
                <div className="flex flex-col px-5 gap-5 py-5">
                  <div>
                    <DialogTitle className="text-xl font-semibold ">
                      Summary Metrics Explained
                    </DialogTitle>
                    <p>
                      The summary page shows aggregated metrics grouped by
                      month, quarter and year. This page helps you see how your
                      system has performed over the selected period.
                    </p>
                  </div>
                  <div className="absolute right-0 top-0 p-5">
                    <button
                      onClick={() => closeModal(false)}
                      className="text-gray-500 hover:text-gray-800"
                    >
                      <span className="sr-only">Close</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="h-6 w-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      <h1 className="border-b pb-2 text-lg font-semibold">
                        Open Trades
                      </h1>
                      <p className="flex flex-col items-start py-[0.2rem]">
                        The number of trades that are open at the start of the
                        period.
                      </p>
                    </div>
                    <div>
                      <h1 className="border-b pb-2 text-lg font-semibold">
                        New trades
                      </h1>
                      <p className="flex flex-col items-start py-[0.2rem]">
                        The number of new trades that are initiated during the
                        period.
                      </p>
                    </div>
                    <div>
                      <h1 className="border-b pb-2 text-lg font-semibold">
                        Fully closed
                      </h1>
                      <p className="flex flex-col items-start py-[0.2rem]">
                        The number of trades that were fully closed during the
                        period.
                      </p>
                    </div>
                    <div>
                      <h1 className="border-b pb-2 text-lg font-semibold">
                        Partially Closed
                      </h1>
                      <p className="flex flex-col items-start py-[0.2rem]">
                        The number of trades that were partially closed during
                        the period.
                      </p>
                    </div>
                    <div>
                      <h1 className="border-b pb-2 text-lg font-semibold">
                        Win Rate %
                      </h1>
                      <p className="flex flex-col items-start">
                        Number of “fully closed” winning trades ÷ Total number
                        of “fully closed” trades
                      </p>
                    </div>
                    <div>
                      <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                        Avg. RPT
                      </h1>
                      <p className="flex flex-col items-start">
                        Average RPT for “fully closed” trades during the period.
                      </p>
                    </div>
                    <div>
                      <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                        Avg. Loss
                      </h1>
                      <p className="flex flex-col items-start">
                        Average Loss per each “fully closed” trade during the
                        period.
                      </p>
                    </div>
                    <div>
                      <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                        Avg. Gain
                      </h1>
                      <p className="flex flex-col items-start">
                        Average Gain per each “fully closed” trade during the
                        period.
                      </p>
                    </div>
                    <div>
                      <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                        ARR
                      </h1>
                      <p className="flex flex-col items-start">
                        Average Risk Reward Ratio = Avg. Gain ÷ Avg. Loss.
                      </p>
                    </div>
                    <div>
                      <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                        Profit
                      </h1>
                      <p className="flex flex-col items-start">
                        Sum total of realised profits from “fully closed” and
                        “partially closed” trades during the period.
                      </p>
                    </div>
                    <div>
                      <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                        Charges
                      </h1>
                      <p className="flex flex-col items-start">
                        Sum total of charges incurred for “fully closed” and
                        “partially closed” trades during the period.
                      </p>
                    </div>
                    <div>
                      <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                        Net Profit
                      </h1>
                      <p className="flex flex-col items-start">
                        Profit - Charges
                      </p>
                    </div>
                    <div>
                      <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                        Account Value
                      </h1>
                      <p className="flex flex-col items-start">
                        Account value as on the last day of the period.
                        <code className="relative rounded bg-gray-a4 px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                          = Net deposits till the last day of the period +
                          Realised Gains till the last day of the period.
                        </code>
                      </p>
                    </div>
                    <div>
                      <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                        Capital Deployed
                      </h1>
                      <p className="flex flex-col items-start">
                        Capital Deployed as on the last day of the period.
                        <code className="relative rounded bg-gray-a4 px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                          = Total Deposits - Total Withdrawals till the last day
                          of the period
                        </code>
                      </p>
                    </div>
                    <div>
                      <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                        RoCD
                      </h1>
                      <p className="flex flex-col items-start">
                        RoCD, which stands for Return on Capital Deployed,
                        represents the percentage gain on your capital deployed
                        in a given period. It is calculated as the sum total of
                        RoCD of each “fully closed” or “partially closed” trade
                        during the period. To calculate the RoCD of each trade,
                        the net profit of the trade is divided by the Capital
                        Deployed (net funds added) as of the trade's open date.
                        ROCD and ROSV are two different metrics used to
                        calculate returns in trading. ROCD gives the percentage
                        return over the initial capital invested, while ROSV
                        calculates the percentage return over the starting
                        account value for the year, including any deposits or
                        withdrawals made in the current year till the trade was
                        opened. ROSV is useful in measuring the annual return
                        based on the starting account value for that year, while
                        ROCD calculates returns over the net funds added till
                        trade open date.
                        <code className="relative rounded bg-gray-a4 px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                          = sum total of RoCD of each “fully closed” or
                          “partially closed” trade during the period
                        </code>
                      </p>
                    </div>
                    <div>
                      <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                        Adjusted Account Value
                      </h1>
                      <p className="flex flex-col items-start">
                        It is the Adjusted Account Value as on the last day of
                        the period.
                        <code className="relative rounded bg-gray-a4 px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                          = Starting Account Value (1st Jan) + Net funds added
                          in the current year till the end of the period.
                        </code>
                      </p>
                    </div>
                    <div>
                      <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                        RoSAV
                      </h1>
                      <div>
                        <div>
                          RoSAV, which stands for Return on Starting Account
                          Value, represents the percentage gain on your
                          <span className="font-bold px-2">
                            Starting Account Value (Adj.)
                          </span>{" "}
                          for a given period. It is calculated as the sum total
                          of RoSV of each “fully closed” or “partially closed”
                          trade during the period. To calculate the RoSV of each
                          trade, the net profit of the trade is divided by the
                          Starting Account Value (Adjusted) as of the trade's
                          open date. RoCD and RoSV are two different metrics
                          used to calculate returns in trading. RoCD gives the
                          percentage return over the initial capital invested,
                          while RoSV calculates the percentage return over the
                          starting account value for the year, including any
                          deposits or withdrawals made in the current year till
                          the trade was opened. RoSV is useful in measuring the
                          annual return based on the starting account value for
                          that year, while RoCD calculates returns over the net
                          funds added till trade open date.
                        </div>
                        <code className="relative rounded bg-gray-a4 px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                          RoSV for a trade = Net Profit ÷ Starting Account Value
                          as on the trade open date
                        </code>
                      </div>
                    </div>
                    <div>
                      <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                        Summary Verification steps
                      </h1>
                      <div className="px-5">
                        <ol className=" list-decimal">
                          <li>Export all trades with relevant metrics.</li>
                          <li>
                            In the exported excel, select everything and add
                            filters.
                          </li>
                          <li>
                            Fully Closed Trades + Partially Closed Trades =
                            Trade Entry with date within the period + Trade Exit
                            with date within the period. Add a filter to the
                            date column and select all the months that come
                            within the respective period.
                          </li>
                          <li>
                            Fully Closed Trades = (Exit percent = 100) AND (Date
                            of Trade Entry is within the period OR Max(Trade
                            Exit dates) is within the period). Add a date
                            filter, an Exit Percent 100 filter, and a max date
                            filter.
                          </li>
                          <li>
                            Now add/count respective columns to derive various
                            values and verify the calculations on the summary
                            page.
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default SummaryExplanation;
