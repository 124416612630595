// Add New Symbol

import React, { FormEvent, useRef, useState } from "react";
import ModalHeadless from "../../UI/modals/HeadlessOverlay";
import {
  Button,
  Checkbox,
  Description,
  Field,
  Fieldset,
  Input,
  Label,
} from "@headlessui/react";
import PlusIcon from "../../UI/Icons/Journals/PlusIcon";
import { useThemeStore, useToastStore } from "../../../context/ThemeState";
import cookies from "../../../Helpers/Cookies";
import { CallBacks } from "../../../Config/Callbacks";
import toastContainer from "../../../context/ToastConfiguration";
import { CheckIcon } from "@heroicons/react/16/solid";
import localStorageHelpers from "../../../Helpers/LocalStorage";
import AddFunds from "../../UI/buttons/CreateFundsButton";
import { SERVER_URL } from "../../../Config/Constants";

// Any symbol you add are private to this journal and won't be shared across your other journals.

const CreateFunds = React.memo(({ onClose }: { onClose: () => void }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [requestStatus, setRequestStatus] = useState({
    type: "",
    message: "",
  });
  const [formState, setFormState] = useState({
    date: "",
    amount: 0,
    type: "",
  });
  const { theme } = useThemeStore();
  const formRef = useRef<HTMLFormElement>(null);
  const dateInputRef = useRef<any>(null);
  const user = cookies.get("getuser")();
  const journal_id = localStorageHelpers.get("getdata")("journal_id");

  async function handleCreateSymbol(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      const formData = {
        ...formState,
        userId: user.id,
        journalId: journal_id,
        date: formState.date
          ? new Date(formState.date).toISOString()
          : formState.date,
      };
      setIsLoading(true);
      const callback = new CallBacks();
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      const response = await callback.POST(
        `${SERVER_URL}/api/funds/create`,
        {
          headers,
          body: JSON.stringify(formData),
        }
      );
      setRequestStatus({
        type: "success",
        message: response?.message,
      });
    } catch (error: any) {
      setRequestStatus({
        type: "error",
        message: error.message || error?.title,
      });
    } finally {
      setIsLoading(false);
      formRef?.current?.reset();
      onClose();
    }
  }
  return (
    <>
      <Button
        className={`inline-flex items-center justify-center gap-2 rounded-md data-[focus]:outline-1 focus:outline-none py-1.5 px-3 text-sm/6 font-semibold shadow-inner  ${
          theme === "light"
            ? "text-gray-800 bg-gray-100"
            : " bg-stone-800 text-white"
        }`}
        onClick={() => setIsOpen(true)}
      >
        <PlusIcon styleString="h-5 w-5" />
        <span className="md:block hidden">Funds</span>
      </Button>
      <ModalHeadless
        isOpen={isOpen}
        close={() => setIsOpen(false)}
        title="Add Funds"
      >
        <p className={`text-gray-400 text-sm`}>You can add more funds</p>
        {requestStatus?.type !== "" && (
          <div className="w-full">
            {toastContainer.get(requestStatus.type.toUpperCase())({
              message: requestStatus.message,
              close: () => setRequestStatus({ message: "", type: "" }),
            })}
          </div>
        )}
        <div className="md:w-[400px] w-full px-4">
          <form onSubmit={handleCreateSymbol} ref={formRef}>
            <Fieldset className={"flex flex-col gap-3"}>
              <Field>
                <Label
                  className={`text-sm/6 font-medium ${
                    theme === "light" ? "text-black" : "text-white"
                  }`}
                >
                  Date
                </Label>
                <Description className={"text-sm/6 text-gray-400"}>
                  What date you added your funds
                </Description>
                <Input
                  type="date"
                  ref={dateInputRef}
                  onClick={() => dateInputRef?.current?.showPicker()}
                  className={`w-full px-4 py-1.5 rounded-lg ${
                    theme === "light"
                      ? "outline-none border focus:border-2 focus:border-blue-500 border-gray-300 "
                      : "outline-none border focus:border-2 focus:border-blue-500 border-stone-700 bg-black/15 text-white"
                  }`}
                  onChange={(e) => {
                    setSelectedDate(e.target.value);
                    const date = new Date(e.target.value);
                    setFormState((prev) => ({
                      ...prev,
                      date: date.toISOString(),
                    }));
                  }}
                  value={selectedDate}
                />
              </Field>
              <Field>
                <Label
                  className={`text-sm/6 font-medium ${
                    theme === "light" ? "text-black" : "text-white"
                  }`}
                >
                  Type
                </Label>
                <div className="flex flex-col gap-4 mt-2">
                  <div className="flex items-center gap-3">
                    <Checkbox
                      checked={formState.type === "deposite"}
                      onChange={() =>
                        setFormState(() => ({ ...formState, type: "deposite" }))
                      }
                      className={`group size-6 rounded-md bg-white/10 p-1 ring-1 ring-white/15 ring-inset data-[checked]:bg-white ${
                        formState.type !== "deposite"
                          ? theme === "light"
                            ? "border-stone-400 border"
                            : "border border-stone-800"
                          : ""
                      }`}
                    >
                      <CheckIcon className="hidden size-4 fill-black group-data-[checked]:block" />
                    </Checkbox>
                    <p
                      className={`font-semibold ${
                        theme === "light" ? "text-black" : "text-white"
                      }`}
                      onClick={() =>
                        setFormState(() => ({ ...formState, type: "deposite" }))
                      }
                    >
                      Deposit
                    </p>
                  </div>
                  <div className="flex items-center gap-3">
                    <Checkbox
                      checked={formState.type === "withdraw"}
                      onChange={() =>
                        setFormState(() => ({ ...formState, type: "withdraw" }))
                      }
                      className={`group size-6 rounded-md bg-white/10 p-1 ring-1 ring-white/15 ring-inset data-[checked]:bg-white ${
                        formState.type !== "withdraw"
                          ? theme === "light"
                            ? "border-stone-400 border"
                            : "border border-stone-800"
                          : ""
                      }`}
                    >
                      <CheckIcon className="hidden size-4 fill-black group-data-[checked]:block" />
                    </Checkbox>
                    <p
                      className={`font-semibold ${
                        theme === "light" ? "text-black" : "text-white"
                      }`}
                      onClick={() =>
                        setFormState(() => ({ ...formState, type: "withdraw" }))
                      }
                    >
                      Withdraw
                    </p>
                  </div>
                </div>
              </Field>
              <Field>
                <Label
                  className={`text-sm/6 font-medium ${
                    theme === "light" ? "text-black" : "text-white"
                  }`}
                >
                  Amount
                </Label>
                <Description className={"text-sm/6 text-gray-400"}>
                  Enter the amount you want to withdraw or deposite
                </Description>
                <Input
                  type="number"
                  className={`w-full px-4 py-1.5 rounded-lg ${
                    theme === "light"
                      ? "outline-none border focus:border-2 focus:border-blue-500 border-gray-300 "
                      : "outline-none border focus:border-2 focus:border-blue-500 border-stone-700 bg-black/15 text-white"
                  }`}
                  onChange={(e) =>
                    setFormState(() => ({
                      ...formState,
                      amount: Number(e.target.value),
                    }))
                  }
                  value={formState.amount}
                />
              </Field>
              <AddFunds isLoading={isLoading} />
            </Fieldset>
          </form>
        </div>
      </ModalHeadless>
    </>
  );
});
export default CreateFunds;
