import { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useThemeStore, useToastStore } from "../../../context/ThemeState";
import EditIcon from "../../UI/Icons/Journals/EditIcon";
import DeleteIcon from "../../UI/Icons/Journals/DeleteIcon";
import NotesIcon from "../../UI/Icons/Journals/NotesIcon";
import { CallBacks } from "../../../Config/Callbacks";
import localStorageHelpers from "../../../Helpers/LocalStorage";
import { useNavigate } from "react-router-dom";
import Tabs from "./Tabs";
import { SERVER_URL } from "../../../Config/Constants";

export default function FundsTable() {
  const { theme } = useThemeStore();
  const { showToast } = useToastStore();
  const [isOpenHelp, setIsOpenHelp] = useState(false);
  const [currentTab, setCurrentTab] = useState<"Funds" | "Adjustments">(
    "Funds"
  );
  const columnDefs: any = useMemo(
    () => [
      { headerName: "Date", field: "date" },
      { headerName: "Type", field: "type" },
      { headerName: "Amount", field: "amount" },
      {
        headerName: "", // No header name for the controls column
        field: "controls",
        flex: 1,
        sortable: false,
        cellRenderer: (params: any) => (
          <div className="h-[40px] font-semibold flex items-center justify-center lg:gap-20 md:gap-10 gap-5">
            <button
              onClick={() => {
                console.log(params);
              }}
              className={`border w-[100px] rounded-lg h-[35px] flex items-center justify-center gap-3 active:ring-2 active:ring-blue-700 ${
                theme === "light"
                  ? "bg-white/50"
                  : "bg-white/20 border-white/20"
              } `}
            >
              <EditIcon height={5} width={5} />
              Edit
            </button>
            <button
              className={`border w-[100px] rounded-lg h-[35px] flex items-center justify-center gap-3 active:ring-2 active:ring-blue-700 ${
                theme === "light"
                  ? "bg-white/50"
                  : "bg-white/20 border-white/20"
              } `}
              onClick={() => handleDelete(params.data.id)}
            >
              <DeleteIcon stylesString="h-5 w-5" />
              Delete
            </button>
            <button
              className={`border w-[100px] rounded-lg h-[35px] flex items-center justify-center gap-3 active:ring-2 active:ring-blue-700 ${
                theme === "light"
                  ? "bg-white/50"
                  : "bg-white/20 border-white/20"
              } `}
              onClick={() => handleNotes(params)}
            >
              <NotesIcon stylesString="h-5 w-5" />
              Notes
            </button>
          </div>
        ),
      },
    ],
    [theme]
  );
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        const callbacks = new CallBacks();
        const headers = new Headers();
        const journal_id = localStorageHelpers?.get("getdata")("journal_id");
        if (!journal_id) {
          navigate("/journal");
        }
        headers.append("Content-Type", "application/json");
        const response = await callbacks.GET(
          `${SERVER_URL}/api/funds/journal/${journal_id}`,
          {
            headers,
          }
        );
        setRowData(response.content);
      } catch (error: any) {
        showToast(error.message || error.title, "error");
      }
    })();
  }, [isOpen]);

  // const handleEdit = async (params: any) => {
  //   try {
  //     const response = await fetch(
  //       `${SERVER_URL}/api/funds/delete/${params}`
  //     );
  //     if (response.ok) {
  //       const responseData = await response.json();
  //       showToast(responseData.message, "success");
  //     } else {
  //       const responseData = await response.json();
  //       showToast(responseData.message, "error");
  //     }
  //   } catch (error: any) {
  //     showToast(error.message, "error");
  //   }
  // };

  const handleDelete = async (params: any) => {
    try {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      const response = await fetch(`${SERVER_URL}/api/funds/delete/${params}`, {
        method: "DELETE",
        headers,
      });
      if (response.ok) {
        const responseData = await response.json();
        showToast(responseData.message, "success");
      } else {
        const responseData = await response.json();
        showToast(responseData.message, "error");
      }
    } catch (error: any) {
      showToast(error.message, "error");
    }
  };

  const handleNotes = (params: any) => {
    console.log("Notes clicked", params.data);
  };

  return (
    <div className="px-5 pb-5">
      <Tabs
        currentTab={currentTab}
        setCurrentTab={(value: "Funds" | "Adjustments") => {
          setCurrentTab(value);
        }}
        setClose={() => setIsOpen(false)}
        isOpen={isOpenHelp}
        setIsOpenHelp={(value: boolean) => {
          setIsOpenHelp(value);
        }}
      />
      <div className={isOpenHelp ? "block" : "hidden"}>
        <ol className=" list-decimal flex flex-col gap-3 px-5 py-5 font-semibold italic text-gray-500">
          <li>
            Capital Deployed refers to the cash amount utilized for trading,
            which is equivalent to the funds deposited into your broker account.
          </li>
          <li>
            When you withdraw from your broker account, the amount is subtracted
            from your Capital Deployed total.
          </li>
          <li>
            To ensure accurate tracking of your capital and ROI %, please reduce
            any amount used for purposes other than trading our system from the
            capital deployed. For example, if you invested ₹50,000 in Gold
            Bonds, ETFs, or stocks for long-term purposes, subtract that amount
            from your Capital Deployed as it is not being used for trading our
            system. Simply select "Withdraw Capital" to adjust accordingly.
          </li>
        </ol>
      </div>
      <div
        className={` md:h-[80vh] h-[85vh] font-semibold ${
          theme === "light"
            ? "bg-white ag-theme-alpine"
            : "bg-stone-900  ag-theme-alpine-dark rounded-3xl"
        }`} // applying the Data Grid theme
      >
        <AgGridReact
          columnDefs={columnDefs}
          headerHeight={35}
          rowData={rowData}
          className={`border-gray-900`}
        />
      </div>
    </div>
  );
}
