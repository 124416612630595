import React, { useEffect } from "react";
import SignIn from "./components/auth/SignIn";
import ForgetPassword from "./components/auth/ForgetPassword";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Journals from "./components/Journals/Journal";
import { useThemeStore } from "./context/ThemeState";
import Header from "./components/UI/user/Headers";
import DashboardMain from "./components/user/dashboard/Dashboard";
import Trades from "./components/user/trades/Trades";
import Symbols from "./components/user/symbols/Symbols";
import FundsTable from "./components/user/funds/Funds";
import SummaryList from "./components/user/summary/SummaryList";
import ResetPassword from "./components/auth/ResetPassword";
import Toast from "./context/Toast";
import TradesDetails from "./components/user/TradesDetails/TradesDetails";
import PositionManager from "./components/user/Position/PositionManager";
import FeedContainer from "./components/user/feed/FeedContainer";
import ProfilePage from "./components/user/Profile/ProfilePage";
import Register from "./components/auth/Signup";

function App() {
  const { theme, setTheme } = useThemeStore();
  useEffect(() => {
    const currentTheme = window.matchMedia("(prefers-color-scheme: dark)")
      .matches
      ? "light"
      : "dark";

    setTheme(currentTheme);

    const handleThemeChange = (e: any) => {
      setTheme(e.matches ? "dark" : "light");
    };

    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", handleThemeChange);

    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", handleThemeChange);
    };
  }, [setTheme]);
  return (
    <BrowserRouter>
      <Header />
      <Toast />
      <AppComponent />
    </BrowserRouter>
  );
}

export default App;

function AppComponent() {
  const { theme } = useThemeStore();
  const { pathname } = useLocation();
  return (
    <div
      className={`min-h-screen ${pathname.startsWith("/journal/") && "pt-14"} ${theme === "light"
        ? "bg-white text-gray-700"
        : "bg-stone-950 text-gray-300"
        }`}
    >
      <Routes>
        <Route path="/auth/signin" element={<SignIn />} />
        <Route path="/auth/signup" element={<Register />} />
        <Route path="/auth/forgetpassword" element={<ForgetPassword />} />
        <Route path="/auth/resetpassword/:token" element={<ResetPassword />} />
        <Route path="/journals" element={<Journals />} />
        <Route path="/journal/dashboard" element={<DashboardMain />} />
        <Route path="/journal/trades" element={<Trades />} />
        <Route path="/journal/trades/:trades_id" element={<TradesDetails />} />
        <Route path="/journal/symbols" element={<Symbols />} />
        <Route path="/journal/funds" element={<FundsTable />} />
        <Route path="/journal/summary" element={<SummaryList />} />
        <Route path="/journal/positions" element={<PositionManager />} />
        <Route path="/journal/feed" element={<FeedContainer />} />
        <Route path="/journal/profile" element={<ProfilePage />} />
      </Routes>
    </div>
  );
}
