import { useState, useEffect } from "react";

const useFetch = (url: string, headers: Headers) => {
  const [data, setData] = useState(null); // To store the fetched data
  const [loading, setLoading] = useState(true); // To track the loading state
  const [error, setError] = useState(null); // To handle errors if they occur

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Start loading when fetching data
        const response = await fetch(url, { method: "GET", headers }); // Fetch data from the API

        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.statusText}`);
        }

        const result = await response.json(); // Parse the JSON response
        setData(result); // Set the fetched data
      } catch (err: any) {
        setError(err.message); // Set the error if any occurs
      } finally {
        setLoading(false); // Stop loading once the fetch completes
      }
    };

    if (url) {
      fetchData(); // Fetch data only if a valid URL is provided
    }
  }, [url]); // Re-run the effect if the URL changes

  // Return data, loading, and error as an array
  return [data, loading, error];
};

export default useFetch;
