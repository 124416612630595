import { useThemeStore } from "../../../context/ThemeState";

const Section1 = () => {
  const { theme } = useThemeStore();
  return (
    <div className="flex my-3 gap-4 overflow-auto">
      {/* Open Trades Card */}
      <div
        className={`rounded-lg border shadow-sm flex-grow ${
          theme === "light"
            ? "border-gray-200 bg-gray-100"
            : "border-stone-800 bg-stone-900"
        }`}
      >
        <div className="flex p-3 text-sm font-bold whitespace-nowrap justify-between">
          <span>Open Trades</span>
          <a className="text-xs underline" href="/journal/positions">
            View
          </a>
        </div>
        <div className="p-3 pt-0">
          <div className="text-2xl">3</div>
        </div>
      </div>

      {/* Total Exposure Card */}
      <div
        className={`rounded-lg border shadow-sm flex-grow ${
          theme === "light"
            ? "border-gray-200 bg-gray-100"
            : "border-stone-800 bg-stone-900"
        }`}
      >
        <div className="flex p-3 text-sm font-bold whitespace-nowrap">
          Total Exposure
        </div>
        <div className="p-3 pt-0 flex items-end gap-2">
          <span className="text-2xl text-right">3,94,693</span>
          <span className="text-lg">42.51%</span>
        </div>
      </div>

      {/* Total Open Risk Card */}
      <div
        className={`rounded-lg border shadow-sm flex-grow ${
          theme === "light"
            ? "border-gray-200 bg-gray-100"
            : "border-stone-800 bg-stone-900"
        }`}
      >
        <div className="flex p-3 text-sm font-bold whitespace-nowrap">
          Total Open Risk
        </div>
        <div className="p-3 pt-0 flex items-end gap-2">
          <span className="text-2xl text-right">-5,014</span>
          <span className="text-lg">-0.54%</span>
        </div>
      </div>
    </div>
  );
};

export default Section1;
