import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import dashboard from "./dashboard.json";

export default function DashboardMain() {
  return (
    <div className="flex flex-col gap-5 px-5 py-5">
      <div>
        <p className="font-bold text-xl">Position Manager</p>
        <Section1 />
      </div>
      <div>
        <p className="font-bold text-xl">Overview</p>
        <Section2 />
      </div>
      <Section3 data={dashboard} />
      <Section4 />
    </div>
  );
}
