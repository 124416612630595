import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import { useEffect, useState } from "react";
import { useThemeStore } from "../../../context/ThemeState";
import { _ColumnGridApi } from "ag-grid-community/dist/types/core/api/gridApi";
import { CallBacks } from "../../../Config/Callbacks";
import {
  AdjustmentsHorizontalIcon,
  ArrowRightIcon,
} from "@heroicons/react/16/solid";
import TabButtons from "./TabButtons";
import { Link } from "react-router-dom";
import localStorageHelpers from "../../../Helpers/LocalStorage";
import { TradesCalculator } from "../../../Helpers/TradesCalculator";
import { SERVER_URL } from "../../../Config/Constants";
export default function Trades() {
  const { theme } = useThemeStore();
  const [rowData, setRowData] = useState<any>([]);

  const tradesCalculator = new TradesCalculator();
  useEffect(() => {
    (async () => {
      try {
        const journal_id = localStorageHelpers.get("getdata")("journal_id");
        const callbacks = new CallBacks();
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        const response = await callbacks.GET(
          `${SERVER_URL}/api/trades/journal/${journal_id}`,
          { headers }
        );

        setRowData(response?.content);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const [columnDefs, setColumnDefs] = useState<any>([
    {
      headerName: "#",
      rowHeight: 5,
      height: 5,
      field: "index",
      pinned: "left",
      width: 50,
      valueGetter: (params: any) => params.node.rowIndex + 1, // Row index (1-based index)
      cellStyle: {
        borderRight: "1px solid gray",
      },
    },

    {
      headerName: "Symbol",
      rowHeight: 5,
      height: 5,
      field: "symbol",
      pinned: "left",
      valueGetter: (params: any) => params.data.symbol?.symbol,
      cellRenderer: (params: any) => (
        <Link to={`/journal/trades/${params.data.id}?ref=trades`}>
          <p>{params?.data?.symbol?.name}</p>
        </Link>
      ),
      cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
        textDecoration: "underline",
      },
    }, // Assuming SymbolEntity has a name field
    {
      headerName: "Date",
      rowHeight: 5,
      height: 5,
      field: "date",
      cellRenderer: (params: any) =>
        new Date(params.value).toLocaleDateString(), // Format date
      cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
    },

    {
      headerName: "Sector",
      rowHeight: 5,
      height: 5,
      field: "symbol.sector",
      cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
    }, // enum: BUY/SELL

    {
      headerName: "Industry",
      rowHeight: 5,
      height: 5,
      field: "symbol.industry",
      cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
    },
    {
      headerName: "Quantity",
      rowHeight: 5,
      height: 5,
      field: "quantity",
      cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
    },
    {
      headerName: "Entry",
      rowHeight: 5,
      height: 5,
      field: "price",
      cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
      cellRenderer: (params: any) =>
        params?.data?.price ? Number(params?.data?.price) : 0, // Format date
    },
    {
      headerName: "SL",
      rowHeight: 5,
      height: 5,
      field: "stoploss",
      cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
      cellRenderer: (params: any) =>
        params?.data?.stoploss ? Number(params?.data?.stoploss).toFixed(0) : 0, // Format date
    },
    {
      headerName: "SL %",
      rowHeight: 5,
      height: 5,
      field: "slPercent",
      cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
      cellRenderer: (params: any) =>
        params?.data?.slPercent ? (
          <>{Number(params?.data?.slPercent).toFixed(2)}%</>
        ) : (
          0
        ), // Format date
    },
    {
      headerName: "Position Size",
      field: "positionSize",
      rowHeight: 5,
      height: 5,
      cellRenderer: (params: any) => {
        return Number(params.data.price * params.data.quantity).toFixed(0);
      },
      cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
    },
    {
      headerName: "Position Size %",
      rowHeight: 5,
      height: 5,
      field: "positionSizePercent",
      cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
      cellRenderer: (params: any) => {
        return params?.data?.positionSizePercent ? (
          <>{Number(params?.data?.positionSizePercent).toFixed(2)}%</>
        ) : (
          0
        );
      },
    },
    {
      headerName: "RPT",
      field: "rpt",
      rowHeight: 5,
      height: 5,
      cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
      cellRenderer: (params: any) => {
        return params?.data?.rpt ? Number(params?.data?.rpt).toFixed(0) : 0;
      },
    },
    {
      headerName: "RPT %",
      field: "rptPercent",
      rowHeight: 5,
      height: 5,
      cellRenderer: (params: any) => {
        return params?.data?.rptPercent ? (
          <>{Number(params?.data?.rptPercent).toFixed(1)} %</>
        ) : (
          0
        );
      },
      cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
    },

    {
      headerName: "Exit %",
      field: "totalexitPercent",
      rowHeight: 5,
      height: 5,
      cellRenderer: (params: any) => {
        return params?.data?.totalExitPercent ? (
          <>{Number(params?.data?.totalExitPercent).toFixed(0)} %</>
        ) : (
          0
        );
      },
      cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
    },
    {
      headerName: "Exit Price",
      rowHeight: 5,
      height: 5,
      field: "exitPrice",
      cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
    },
    {
      headerName: "Gain %",
      field: "totalGainPercent",
      rowHeight: 5,
      height: 5,
      cellRenderer: (params: any) => {
        return params?.data?.totalGainPercent ? (
          <>{Number(params?.data?.totalGainPercent).toFixed(2)} %</>
        ) : (
          0
        );
      },
      cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
    },

    {
      headerName: "Capital Deployed",
      rowHeight: 5,
      height: 5,
      field: "capitalDeployed",
      cellRenderer: (params: any) => {
        return params?.data?.capitalDeployed ? (
          <>{Number(params?.data?.capitalDeployed).toFixed(0)}</>
        ) : (
          0
        );
      },
      cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
    },
    {
      headerName: "RoCD",
      field: "RoCD",
      rowHeight: 5,
      height: 5,
      cellRenderer: (params: any) => {
        return Number(
          tradesCalculator?.RoCD(
            params?.data?.netProfit,
            params?.data?.capitalDeployed
          )
        ).toFixed(2);
      },
      cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
    },
    {
      headerName: "Starting Account Value",
      rowHeight: 5,
      height: 5,
      field: "capital",
      cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
      cellRenderer: (params: any) => {
        return params?.data?.capital ? (
          <>{Number(params?.data?.capital).toFixed(0)}</>
        ) : (
          0
        );
      },
    },
    {
      headerName: "RoSV",
      rowHeight: 5,
      height: 5,
      cellRenderer: (params: any) => {
        return Number(params.data.netProfit / params.data.capitalDeployed * 100).toFixed(2);
      },
      cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
    },
    {
      headerName: "Account Value",
      rowHeight: 5,
      height: 5,
      field: "deployedValue",
      cellRenderer: (params: any) => {
        return params?.data?.capital
          ? Number(params?.data?.capital).toFixed(0)
          : 0;
      },
      cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
    },
    {
      headerName: "Account Gain %",
      rowHeight: 5,
      height: 5,
      field: "gainPercent",
      cellRenderer: (params: any) => {
        return params?.data?.totalGainPercent
          ? Number(params?.data?.totalGainPercent).toFixed(2)
          : 0;
      },
      cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
    },
    {
      headerName: "Days",
      rowHeight: 5,
      height: 5,

      field: "holdingDays",
      cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
    },
    {
      headerName: "RR",
      rowHeight: 5,
      height: 5,
      field: "rrr",
      cellRenderer: (params: any) => {
        return params?.data?.rrr ? Number(params?.data?.rrr).toFixed(1) : 0;
      },
      cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
    },
    {
      headerName: "Charges",
      rowHeight: 5,
      height: 5,
      field: "charges",
      cellRenderer: (params: any) => {
        return params?.data?.charges
          ? Number(params?.data?.charges).toFixed(0)
          : 0;
      },
      cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
    },

    {
      headerName: "Net Profit",
      rowHeight: 5,
      height: 5,
      field: "netProfit",
      cellRenderer: (params: any) => {
        return params?.data?.netProfit
          ? Number(params?.data?.netProfit).toFixed(0)
          : 0;
      },
      pinned: "right",
      width: 120,
      cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
    },
    {
      headerName: "Actions",
      rowHeight: 5,
      height: 5,
      field: "actions",
      pinned: "right",
      width: 130,
      cellRenderer: (params: any) => {
        return (
          <div className="flex items-center gap-5 justify-center h-full">
            <button className="btn-edit" data-action="edit">
              <p>
                <AdjustmentsHorizontalIcon className="h-5 w-5" />
              </p>
            </button>
            <Link to={`/journal/trades/${params.data.id}?ref=trades`}>
              <button className="btn-delete" data-action="delete">
                <p>
                  <ArrowRightIcon className="h-5 w-5" />
                </p>
              </button>
            </Link>
          </div>
        );
      },
      cellStyle: {
        borderleft: "1px solid gray",
      },
    },
  ]);
  const updateColumnDefs = () => {
    const isPhoneWidth = window.innerWidth <= 768; // Adjust the breakpoint as needed
    console.log("Is Phone Width:", isPhoneWidth);

    setColumnDefs((prev: any) =>
      prev.map((item: any) =>
        Object(item).hasOwnProperty("pinned")
          ? { ...item, pinned: isPhoneWidth ? null : item.originalPinned } // Use originalPinned to restore correct pinning
          : item
      )
    );
  };

  useEffect(() => {
    // Ensure columns have a backup of their original pinned state
    setColumnDefs((prev: any) =>
      prev.map((item: any) =>
        Object(item).hasOwnProperty("pinned")
          ? { ...item, originalPinned: item.pinned } // Store the initial pinned value
          : item
      )
    );

    updateColumnDefs(); // Initial setup

    const handleResize = () => updateColumnDefs();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    // wrapping container with theme & size
    <div className="px-5 pb-5">
      <TabButtons />
      <div
        className={` md:h-[80vw] font-semibold ${theme === "light"
          ? "bg-white ag-theme-alpine"
          : "bg-stone-900  ag-theme-alpine-dark"
          }`} // applying the Data Grid theme
        style={{ height: 500 }} // the Data Grid will fill the size of the parent container
      >
        <AgGridReact
          rowData={rowData ? rowData : []}
          columnDefs={columnDefs}
          rowHeight={35}
          headerHeight={35}
        />
      </div>
    </div>
  );
}

/**column names 
 * #

Date

Symbol

Sector

Industry

Quantity

Entry

SL

SL %

Position Size

Position Size %

RPT

RPT %

Exit %

Exit Price

Gain %

Capital Deployed

RoCD

Starting Account Value (Adj.)

RoSV

Account Value

Account Gain %

Days

RR

Charges

Net Profit

Actio
 * 
 */
