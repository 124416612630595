import { create } from "zustand";

interface ThemeState {
  theme: "light" | "dark";
  toggleTheme: () => void;
  setTheme: (newTheme: "light" | "dark") => void;
}
interface ToastStore {
  isVisible: boolean;
  type: "warn" | "info" | "error" | "success" | "";
  message: string;
  closeToast: () => void;
  showToast: (
    message: string,
    type: "warn" | "info" | "error" | "success" | ""
  ) => void;
}

export const useThemeStore = create<ThemeState>((set) => ({
  theme: "light",
  toggleTheme: () =>
    set((state) => ({
      theme: state.theme === "light" ? "dark" : "light",
    })),
  setTheme: (newTheme) => set({ theme: newTheme }),
}));
export const useToastStore = create<ToastStore>((set) => ({
  isVisible: false,
  message: "",
  type: "",
  closeToast: () =>
    set({
      isVisible: false,
      message: "",
      type: "",
    }),
  showToast: (
    message: string,
    type: "warn" | "info" | "error" | "success" | ""
  ) => set({ isVisible: true, message: message, type: type }),
}));
